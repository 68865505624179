const API = 'HealthAlertLevels';

export const API_HEALTH_ALERT_LEVELS = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<HealthAlertLevels, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<HealthAlertLevels, 'id'>) => `${API}/Delete/${id}`,
};

export class HealthAlertLevels {
  id!: string;
  isActive = true;
  labelKey = '';
  rank = 0;
}
