export enum LABELS_KEY {
  APP = 'app',
  CRM = 'crm',
  AGENCY = 'agency',
}

const _API = 'Labels';

export const API_LABELS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({ id }: Pick<Label, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Label, 'id'>) => `${_API}/Delete/${id}`,
};

export class LabelSource {
  id!: string;
  key: LABELS_KEY = LABELS_KEY.CRM;
  title: string = '';
}

export class Label {
  id!: number;
  title: string = '';
  labelKey: string = '';
  longDescription: string = '';
  languageID!: string;
  labelSourceID!: string;
  isActive: boolean = true;
}

export interface ILabel extends Label {
  language: Language;
}

const API = 'Languages';

export const API_LANGUAGES = {
  API: API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<Language, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Language, 'id'>) => `${API}/Delete/${id}`,
};

export enum DIRECTIONS {
  LTR = 1,
  RTL = 2,
}

export class Language {
  id!: string;
  title = '';
  direction: DIRECTIONS = DIRECTIONS.LTR;
  culture = '';
  icon = '';
  currency = '';
  importName = '';
  isoCode = '';
  rank = 0;
  isActive: boolean = true;
  isRTL: boolean = false;
}
