import { API_USERS_CRM, UserCrmProfile, UserCrmProfilePatch } from './models';
import { api } from 'AurionCR/components';
import { setToModel } from 'utils';

export * from './models';

class Service {
  async patchUserCrm(
    data: Pick<UserCrmProfile, 'appIdentityUserID'> &
      Partial<Omit<UserCrmProfile, 'appIdentityUserID'>>,
  ) {
    return api.patch(API_USERS_CRM.PATCH(data), setToModel(new UserCrmProfilePatch(), data));
  }

  async getByUserID(id: UserCrmProfile['appIdentityUserID']) {
    const params = {
      filter: `appIdentityUserID=="${id}"`,
    };

    const {
      data: { value },
    } = await api.get<{ value: UserCrmProfile[] }>(API_USERS_CRM.GET_ALL_DYNAMIC, { params });

    if (!value.length) {
      throw new Error('User not found');
    }

    return { data: value[0] };
  }
}

export const ServiceUserProfiles = new Service();
