import { useEffect } from 'react';
import { apiStatic } from 'AurionCR/components';
import { UseFormMethods } from 'react-hook-form';

export const useRankHelper = (
  setFormLoading: (val: boolean) => void,
  itemID: any,
  url: string,
  setValue: UseFormMethods['setValue'],
) => {
  // init/get rank
  useEffect(() => {
    if (itemID === true) {
      setFormLoading(true);
      apiStatic
        .get(url, {
          params: {
            Select: 'rank',
            OrderBy: 'rank desc',
            Take: 1,
          },
        })
        .then(({ data: { value } }) => {
          setValue('rank', (Number(value[0]?.rank) || 0) + 1);
          setFormLoading(false);
        })
        .catch((error) => {
          setFormLoading(false);
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormLoading, itemID, setValue]);
};
