import { ServiceMediaUploads } from '../media-uploads';
import { createFilterEquals } from 'utils';
import { api } from 'AurionCR/components';
import { AxiosError, AxiosResponse } from 'axios';

export const parseErrorData = <T = Error>(
  error: { message: string } | AxiosError<T>,
  defaultData?: string,
) => {
  const message = defaultData || 'error';
  if (error && 'isAxiosError' in error) {
    const data = error.response?.data;
    return data || new Error(message);
  } else if (error) {
    return new Error(error.message);
  } else {
    return new Error(message);
  }
};

export interface BaseParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

type IsTrue<T extends any, S, E> = T extends true ? S : E;

export type DynamicResult<T extends any, P extends BaseParams = {}> = P extends {
  count: infer U;
}
  ? IsTrue<U, { value: T[]; count: number }, { value: T[] }>
  : { value: T[] };

export type ValueFile = string | { file?: File; value: string; name: string };

class FileField {
  async updateHTML(
    value: { old?: string; new?: string },
    options: { fileName: string },
  ): Promise<string | undefined> {
    const { old: oldValue, new: newValue } = value;
    const { fileName } = options;
    if (oldValue === newValue) {
      return undefined;
    } else if (oldValue && !newValue) {
      const {
        data: { filePath },
      } = await ServiceMediaUploads.uploadHtml({ fileName, htmlContent: '' });
      return filePath;
    } else if (newValue !== undefined) {
      const {
        data: { filePath },
      } = await ServiceMediaUploads.uploadHtml({ fileName, htmlContent: newValue });
      return filePath;
    }
    return undefined;
  }

  async updateFile(value: { old?: ValueFile; new?: ValueFile }): Promise<string | undefined> {
    const { old: oldValue, new: newValue } = value;
    const uploadFile = async () => {
      if (typeof newValue === 'string') {
        return newValue;
      }
      if (typeof newValue === 'object') {
        const {
          data: { filePath },
        } = await ServiceMediaUploads.uploadFile(newValue);
        return filePath;
      }
    };
    if (oldValue === newValue) {
      return undefined;
    }
    if (!oldValue && newValue) {
      return uploadFile();
    }
    if (oldValue && typeof oldValue === 'string' && newValue) {
      await ServiceMediaUploads.tryRemove(oldValue);
      return uploadFile();
    }
    return undefined;
  }

  async updateImage(value: { old?: ValueFile; new?: ValueFile }): Promise<string | undefined> {
    const { old: oldValue, new: newValue } = value;
    const uploadFile = async () => {
      if (typeof newValue === 'object') {
        const {
          data: { filePath },
        } = await ServiceMediaUploads.uploadImage(newValue);
        return filePath;
      }
    };
    if (oldValue === newValue) {
      return undefined;
    }
    if (!oldValue && newValue) {
      return uploadFile();
    }
    if (oldValue && typeof oldValue === 'string') {
      await ServiceMediaUploads.tryRemove(oldValue);
      if (newValue) {
        return uploadFile();
      }
      return '';
    }
    return undefined;
  }
}

export const UpdateFileBehaviour = new FileField();

export const dynamicGetAll = async <T, P extends BaseParams = BaseParams>(
  url: string,
  params?: P,
) => {
  return api.get<T, AxiosResponse<DynamicResult<T, P>>>(url, { params });
};
export const dynamicGetItem = async <T, P extends BaseParams = BaseParams>(
  url: string,
  objFilters: Partial<Record<keyof T, T[keyof T]>>,
  params?: P,
) => {
  const filters = Object.entries(objFilters).map(([key, val]) => createFilterEquals(key, val));
  const _params = {
    ...params,
    filter: [params?.filter, ...filters].filter((v) => v).join('&&'),
  };

  const result = await dynamicGetAll<T, typeof _params>(url, _params);

  const data = result.data.value[0];

  if (!data) {
    throw new Error('record-not-found');
  }

  return { ...result, data };
};
