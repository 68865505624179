const API = 'JewelryCategories';

export const API_JEWELRY_CATEGORIES = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<JewelryCategory, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<JewelryCategory, 'id'>) => `${API}/Delete/${id}`,
};

export enum JEWELRY_CATEGORY_TYPE {
  displaySize = 1,
  displayLength = 2,
  displayNone = 3,
}
export class JewelryCategory {
  id!: number;
  isActive = true;
  catalogName = '';
  jewelryCategoryType = JEWELRY_CATEGORY_TYPE.displayNone;
  icon = '';
  picture = '';
  rank = 0;
}
