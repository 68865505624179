import React, { memo, useMemo } from 'react';
import { CssBaseline, ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core';
import { useSelector } from 'react-redux';
import rtl from 'jss-rtl';
import { create } from 'jss';

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById('jss-insertion-point') || undefined,
});

export const RTLThemeProvider = memo(({ theme, children }: any) => {
  const { direction } = useSelector((state) => (state as any).language);
  const theme_ = useMemo(() => {
    return { ...{}, ...theme, ...{ direction: direction === 2 ? 'rtl' : 'ltr' } };
  }, [direction, theme]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme_}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
});
export default RTLThemeProvider;
