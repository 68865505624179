import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 5.00001V8.00001L19.8687 8C20.5904 9.3394 21 10.8719 21 12.5C21 17.7467 16.7467 22 11.5 22C6.25329 22 2 17.7467 2 12.5C2 7.25329 6.25329 3 11.5 3C13.1281 3 14.6606 3.40957 16 4.13131V2.00001L19 2L22 5.00001ZM19 12.5C19 16.6421 15.6421 20 11.5 20C7.35786 20 4 16.6421 4 12.5C4 8.35786 7.35786 5 11.5 5C15.6421 5 19 8.35786 19 12.5Z"
      fill="current"
    />
  </svg>,
  'DiamondIcon',
);
