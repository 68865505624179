import React, {useEffect} from "react";
import {api, apiStatic} from "AurionCR/components";
import axios, {AxiosRequestConfig} from "axios";
import {normalizeDataBeforeSave, prepareDateToSave} from "utils";


const instances = [apiStatic, api, axios]
const patchDotNet5 = (conf: AxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object
      .entries(normalizeDataBeforeSave(prepareDateToSave(conf.data)))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return {path, value}
      })
  }
  return conf
}

export const AxiosInterceptorPatch: React.FC = ({children}) => {
  useEffect(() => {
    instances
      .map((instance) => {
        return instance.interceptors.request.use(patchDotNet5)
      })
  }, [])

  return (
    <>
      {children}
    </>
  )
}
