import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import Select, {ISelect} from "../../form/select";
import {api, requestError} from "../../helpers";
import {notifyRequestResult} from "../../../store/modules/notify";
import {useDispatch} from "react-redux";
import {merge} from "lodash";

export interface ISelectMixinSettings {
  name?: string;
  values: {
    source: string | any[]; // 'ArticleCategories'
    filter: {
      key?: string; // need to create and filter items by main form data, articleID == "form.data[filter.value]
      keyFromResponse?: string; // this field get after main PATCH/CREATE form data and add to post item
                                // [filter.key] = form.data[filter.keyFromResponse]
      value: string | number | null | undefined // see [filter.key]
    };
    select: {
      key?: string; // main field source, default = 'id'
      link: string; // this field need to connect main select source and this source
    }
  };
}

export const SelectMixin = memo((
  {
    values,
    onChange: OnChange,
    name,
    ...rest
  }: any & ISelect & ISelectMixinSettings) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [source, setSource] = useState<{ values: any[]; map: any; }>({values: [], map: {}});
  // parsers
  const values_ = useMemo(() => {
    return merge({filter: {key: 'id', keyFromResponse: 'id'}, select: {key: 'id'}}, values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // handlers
  const onChange = useCallback((Values: string[], source?) => {
    if (OnChange) {
      const values = Values || [];
      const response = {
        target: {
          value: {
            mixin_: {
              requests: [
                ...values.reduce((result: any, link: string | number) => {
                  if (!source.map[link]) result.push((data: any) => ({
                    method: 'post',
                    url: `${values_.source}/Create`,
                    data: {
                      [values_.select.link]: link,
                      [values_.filter.key]: data[values_.filter.keyFromResponse]
                    }
                  }));
                  return result
                }, []),
                ...source.values.reduce((result: any, val: string | number) => {
                  if (!values.filter(item => item === val)[0]) result.push((data: any) => ({
                    method: 'delete',
                    url: `${values_.source}/Delete/${source.map[val]}`
                  }));
                  return result
                }, [])
              ],
              // updateModel: 'filePath',
              name,
            },
            type_: 'selectMixin',
            value: values,
            name,
          }
        }
      }
      OnChange(response)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OnChange, name, values_]);
  // init
  useEffect(() => {
    if (values_.source && typeof values_.source === 'string' && values_.filter.value) {
      api
        .get(values_.source + '/GetAllDynamic', {
          params: {
            // eslint-disable-next-line
            ['Select']: values_.select.key + ',' + values_.select.link,
            // eslint-disable-next-line
            ['Filter']: `${values_.filter.key} == ${isNaN(Number(values_.filter.value)) ? `"${values_.filter.value}"` : values_.filter.value}`
          }
        })
        .then(response => {
          const source_ = response.data.value.reduce((result: any, item: any) => {
            result.values.push(item[values_.select.link]);
            result.map[item[values_.select.link]] = item[values_.select.key];
            return result
          }, {values: [], map: {}});
          setSource(source_);
          setLoading(false);
          onChange(source_.values, source_);
        })
        .catch(error => dispatch(notifyRequestResult(requestError(error), 'error')))
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values_, setLoading, setSource]);
  // render
  return <Select
    className="mixin-select"
    {...rest}
    name={name}
    multiple
    value={source.values}
    onChange={(e: any) => onChange(e.target.value, source)}
    loading={loading}
  />
});

export default SelectMixin;
