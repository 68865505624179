import { useI18n } from 'AurionCR/components';
import { useCallback } from 'react';

export const useTranslate = () => {
  const { t: trans, loading } = useI18n();

  const t = useCallback(
    (key: string) => {
      return trans(key);
    },
    // eslint-disable-next-line
    [loading],
  );

  return { t };
};
