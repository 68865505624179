import { useCallback, useState } from 'react';
import { IDataGridColumn } from 'AurionCR/components/data-grid/model';
import { api, requestError } from 'AurionCR/components';
import { ModuleExportExcel } from 'modules/export-excel';
import { gidColumnsToExcel } from 'components/helpers';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useDispatch } from 'react-redux';

export const useExportToExcel = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const exportToExcel = useCallback(
    async (p: { url: string; params: any; columns: IDataGridColumn[]; title: string }) => {
      const { columns, url, title } = p;
      setLoading(true);
      try {
        const params = {
          select: columns
            .map(({ field, as, sortable }: any) => {
              if (as) {
                return sortable + ' as ' + field;
              }
              return field;
            })
            .join(','),
        };
        const { data } = await api.get(url, { params: { ...params, ...p.params } });
        const excelColumns = gidColumnsToExcel(columns);

        const { wb_out } = await ModuleExportExcel.export({
          columns: excelColumns,
          data: data.value,
          settings: { title },
        });
        await ModuleExportExcel.save({ wb_out, name: title });
        dispatch(notifyRequestResult('success'));
      } catch (e: any) {
        dispatch(notifyRequestResult(requestError(e.message), 'error'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return {
    exportToExcel,
    loading,
  };
};
