export type ValueFileUploaderFile = { value: string; name: string; size: number; type: string };
export type ValueFileUploader = string | ValueFileUploaderFile;

export enum FILE_FIELD_ERROR {
  SIZE = 'SIZE',
  TYPE = 'TYPE',
  LOAD = 'LOAD',
}

export class ErrorFileField extends Error {
  type: FILE_FIELD_ERROR;

  constructor(message: string, type: FILE_FIELD_ERROR) {
    super(message);
    this.type = type;
  }
}
