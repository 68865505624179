import { lazy } from 'react';
import { IRoute } from 'AurionCR/components/router';
import AppsIcon from '@material-ui/icons/Apps';
import ContactsIcon from '@material-ui/icons/Contacts';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DiamondIcon from 'components/svg-icon/diamond';
import RingIcon from 'components/svg-icon/ring';
import CameraIcon from '@material-ui/icons/Camera';
import { PERMISSIONS } from 'services/user-profiles';

interface IRouteApp extends IRoute {
  permission?: PERMISSIONS;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
  },
  {
    path: '/customers',
    title: 'customers',
    icon: SupervisedUserCircleIcon,
    routes: [
      {
        path: '/customers-list',
        title: 'customers-list',
        component: lazy(() => import(/* webpackChunkName: 'CustomersList' */ 'pages/ZTemp')),
      },
    ],
  },
  {
    path: '/agencies',
    title: 'agencies',
    icon: ContactsIcon,
    routes: [
      {
        path: '/agencies-list',
        title: 'agencies-list',
        component: lazy(() => import(/* webpackChunkName: 'AgenciesList' */ 'pages/ZTemp')),
      },
    ],
  },
  {
    path: '/products',
    title: 'products',
    icon: AppsIcon,
    routes: [
      {
        path: '/products-diamonds',
        title: 'product-diamonds',
        component: lazy(() => import(/* webpackChunkName: 'ProductsDiamonds' */ 'pages/ZTemp')),
      },
      {
        path: '/products-jewelries',
        title: 'product-jewelries',
        component: lazy(() => import(/* webpackChunkName: 'ProductJewelries' */ 'pages/ZTemp')),
      },
    ],
  },
  {
    path: '/pages',
    title: 'pages',
    icon: DescriptionIcon,
    routes: [
      {
        path: '/home-page',
        title: 'home-page',
        component: lazy(() => import(/* webpackChunkName: 'HomePage' */ 'pages/CommonPages/app')),
      },
      {
        path: '/terms-and-condition',
        title: 'terms-and-condition',
        component: lazy(
          () => import(/* webpackChunkName: 'TermsAndCondition' */ 'pages/CommonPages/terms'),
        ),
      },
      {
        path: '/privacy-policy',
        title: 'privacy-policy',
        component: lazy(
          () => import(/* webpackChunkName: 'PrivacyPolicy' */ 'pages/CommonPages/privacy'),
        ),
      },
      {
        path: '/contact-us',
        title: 'contact-us',
        component: lazy(
          () => import(/* webpackChunkName: 'ContactUs' */ 'pages/CommonPages/contact-us'),
        ),
      },
      {
        path: '/thank-you-page',
        title: 'thank-you-page',
        component: lazy(
          () => import(/* webpackChunkName: 'ThankYouPage' */ 'pages/CommonPages/thanks'),
        ),
      },
      {
        path: '/agency',
        title: 'agency',
        component: lazy(() => import(/* webpackChunkName: 'Agency' */ 'pages/CommonPages/agency')),
      },
      {
        path: '/testimonials',
        title: 'testimonials',
        component: lazy(
          () => import(/* webpackChunkName: 'Testimonials' */ 'pages/CommonPages/testimonials'),
        ),
      },
      {
        path: '/about-us',
        title: 'about-us',
        component: lazy(
          () => import(/* webpackChunkName: 'AboutUs' */ 'pages/CommonPages/about-us'),
        ),
      },
      {
        path: '/diamond-list',
        title: 'diamond-list',
        component: lazy(
          () => import(/* webpackChunkName: 'DiamondList' */ 'pages/CommonPages/diamond-list'),
        ),
      },
      {
        path: '/jewelry-list',
        title: 'jewelry-list',
        component: lazy(
          () => import(/* webpackChunkName: 'JewelryList' */ 'pages/CommonPages/jewelry-list'),
        ),
      },
    ],
  },
  {
    path: '/other-pages',
    title: 'other-pages',
    icon: CameraIcon,
    component: lazy(
      () => import(/* webpackChunkName: 'HtmlPageSubjects' */ 'pages/HtmlPageSubjects'),
    ),
  },
  {
    path: '/communication',
    title: 'communication',
    icon: ForumIcon,
    routes: [
      {
        path: '/email-templates',
        title: 'email-templates',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'EmailTemplates' */ 'pages/Communication/EmailTemplates'),
        ),
      },
      {
        path: '/email-service-providers',
        title: 'email-service-providers',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'EmailServiceProviders' */ 'pages/Communication/EmailServiceProviders'
            ),
        ),
      },
      {
        path: '/leads',
        title: 'leads',
        component: lazy(() => import(/* webpackChunkName: 'Leads' */ 'pages/Communication/Leads')),
      },
    ],
  },
  {
    path: '/diamonds',
    title: 'diamonds',
    icon: DiamondIcon,
    routes: [
      {
        path: '/diamonds-list',
        title: 'diamonds-list',
        component: lazy(() => import(/* webpackChunkName: 'diamondAdvanceBrands' */ 'pages/ZTemp')),
      },
      {
        path: '/diamonds-parameters',
        title: 'diamonds-parameters',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'diamondAdvanceBrands' */ 'pages/Diamonds/DiamondsParameters/Diamonds'
            ),
        ),
      },
      {
        path: '/diamonds-images',
        title: 'diamonds-images',
        component: lazy(
          () => import(/* webpackChunkName: 'diamondImages' */ 'pages/Diamonds/DiamondImages'),
        ),
      },
    ],
  },
  {
    path: '/jewelry',
    title: 'jewelry',
    icon: RingIcon,
    routes: [
      {
        path: '/jewelry-list',
        title: 'jewelry-list',
        component: lazy(() => import(/* webpackChunkName: 'JewelryList' */ 'pages/ZTemp')),
      },
      {
        path: '/jewelry-parameters',
        title: 'jewelry-parameters',
        component: lazy(
          () => import(/* webpackChunkName: 'JewelryParameters' */ 'pages/Jewelry/Parameters'),
        ),
      },
    ],
  },
  {
    path: '/app-settings',
    title: 'app-settings',
    icon: SettingsApplicationsIcon,
    permission: PERMISSIONS.EDIT_CRM_USERS,
    routes: [
      {
        path: '/languages',
        title: 'languages',
        component: lazy(
          () => import(/* webpackChunkName: 'Languages' */ 'pages/AppSettings/Languages'),
        ),
      },
      {
        path: '/genders',
        title: 'genders',
        component: lazy(() => import(/* webpackChunkName: 'Genders' */ 'pages/ZTemp')),
      },
      {
        path: '/labels-cms',
        title: 'labels-cms',
        component: lazy(
          () => import(/* webpackChunkName: 'Labels' */ 'pages/AppSettings/Labels/crm'),
        ),
      },
      {
        path: '/labels-app',
        title: 'labels-app',
        component: lazy(
          () => import(/* webpackChunkName: 'Labels' */ 'pages/AppSettings/Labels/app'),
        ),
      },
      {
        path: '/labels-agency',
        title: 'labels-agency',
        component: lazy(
          () => import(/* webpackChunkName: 'LabelsAgency' */ 'pages/AppSettings/Labels/agencies'),
        ),
      },
    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: PERMISSIONS.EDIT_SETTING,
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        permission: PERMISSIONS.EDIT_CRM_USERS,
        component: lazy(() => import(/* webpackChunkName: 'UsersCrm' */ 'pages/Settings/Users')),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(
          () => import(/* webpackChunkName: 'Permissions' */ 'pages/Settings/Permissions'),
        ),
      },
      {
        path: '/general',
        title: 'general-website-setting',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'GeneralWebsiteSetting' */ 'pages/Settings/GeneralWebsiteSetting'
            ),
        ),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'ExternalChannels' */ 'pages/Settings/ExternalChannels'),
        ),
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
];

export default routes;
