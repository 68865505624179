import React, {useEffect} from "react";
import {api, apiStatic, getCookies} from "AurionCR/components";
import axios from "axios";

interface AxiosInterceptorsTokenProps {
}

export const AxiosInterceptorsToken: React.FC<AxiosInterceptorsTokenProps> = ({children}) => {
  useEffect(() => {
    const ids = [api, apiStatic, axios]
      .map((instance) => {
        const id = instance.interceptors.request.use((config) => {
          const token = getCookies(window.authCookieName_ || '');
          if (token) {
            config.headers["Authorization"] = "Bearer " + token;
          }
          return config
        })
        return {id, instance}
      })

    return () => {
      ids
        .forEach(({instance, id}) => {
          instance.interceptors.request.eject(id)
        })
    }
  }, [])

  return <>{children}</>
}
