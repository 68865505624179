import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { requestError } from 'AurionCR/components';
import { useTranslate } from './use-translate';

interface IRequest {
  (...args: any[]): Promise<any>;
}

interface UseRequestNotifyProps<R extends IRequest> {
  withSuccess?: boolean;
  setLoading?: (v: boolean) => void;
  request: R;
}

const defaultSetLoading = () => ({});
export const useRequestNotify = <R extends IRequest>({
  request,
  setLoading = defaultSetLoading,
  withSuccess = true,
}: UseRequestNotifyProps<R>) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const requestWrap = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      try {
        const result = await request(...args);
        withSuccess && dispatch(notifyRequestResult());
        setLoading(false);
        return result;
      } catch (e: any) {
        setLoading(false);
        const message = t(requestError(e, e.message));
        dispatch(notifyRequestResult(message, 'error'));
        throw e;
      }
    },
    [request, setLoading, dispatch, withSuccess, t],
  ) as R;

  return useMemo(() => {
    return {
      requestWrap,
    };
  }, [requestWrap]);
};
