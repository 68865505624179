import React, { useEffect, useMemo } from 'react';
import style from './index.module.scss';
import { Drawer, IconButton } from '@material-ui/core';
import { useOpen } from 'AurionCR/components/hooks';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { MainNav } from 'AurionCR/components/navigation/main';
import { useAuth, UserNav } from 'AurionCR/components';
import { LayoutIconsNav } from '../icons-nav';
import { AppUser } from 'services/account';
import LogoCircle from 'images/logo-small.svg';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'hooks';

interface Props {
  user: AppUser;
  fieldImagePath: keyof AppUser;
}
export const LayoutMobileNav: React.FC<Props> = ({ fieldImagePath }) => {
  const { isOpen, handleToggle, handleClose } = useOpen({ init: false });
  const { t } = useTranslate();
  const { permission } = useAuth();
  const userNav = useMemo(() => {
    if (permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [permission]);
  const history = useHistory();

  useEffect(() => {
    const res = history.listen(() => {
      handleClose();
    });
    return () => {
      res();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <header className={style.header}>
        <IconButton onClick={handleToggle} color={'inherit'}>
          {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <div className={style.logo}>
          <img src={LogoCircle} alt={''} />
        </div>
      </header>
      <Drawer open={isOpen} onClose={handleToggle}>
        <aside className={style.aside}>
          <div className={style.main}>
            <div className={style.mainLight}>
              <UserNav
                menu={userNav}
                visibleTitle={false}
                fieldImagePath={fieldImagePath}
                endIcon={false}
              />
              <LayoutIconsNav />
            </div>
            <div className={style.mainRight}>
              <div className={style.user}>
                <UserNav
                  menu={userNav}
                  visibleAvatar={false}
                  fieldImagePath={fieldImagePath}
                  title={(data: AppUser) => (
                    <>
                      <strong className={style.userName}>{data.name}</strong>
                    </>
                  )}
                />
              </div>
              <div className={style.navigation}>
                <MainNav collapsed={false} accordion={true} />
              </div>
            </div>
          </div>
          <div className={style.copyright}>{t('login-copyright')}</div>
        </aside>
      </Drawer>
    </>
  );
};
