import { ServicePostWithRank } from '../utils';
import { API_LANGUAGES, Language } from './models';

export * from './models';

class ServiceLang {
  async post(data: Language) {
    return ServicePostWithRank.post<Language>({
      apiPost: API_LANGUAGES.POST,
      apiGet: API_LANGUAGES.GET_ALL_DYNAMIC,
      dataPost: data,
    });
  }
}

export const ServiceLanguages = new ServiceLang();
