import React, { useCallback, useEffect, useMemo } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { UserNav } from 'AurionCR/components/navigation/user';
import { LayoutIconsNav } from '../icons-nav';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as LogoCircle } from 'images/logo-small.svg';
import { MainNav } from 'AurionCR/components/navigation/main';
import { useAuth } from 'AurionCR/components';
import { debounce } from 'lodash';
import { AppUser } from 'services/account';
import { useOpen } from 'AurionCR/components/hooks';
import { useTranslate } from 'hooks';

import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  user: AppUser;
  fieldImagePath: keyof AppUser;
}

export const LayoutDesktopNav: React.FC<Props> = ({ user, fieldImagePath }) => {
  const { permission } = useAuth();
  const { t } = useTranslate();
  const [open, setOpen] = React.useState(true);
  const { isOpen: hover, setIsOpen: setHover } = useOpen({ init: false });
  const onToggle = useCallback(
    (bool: boolean) => {
      setOpen(bool);
      localStorage.setItem(`menu_user-${user.appUserID}`, JSON.stringify({ open: bool }));
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    [setOpen, user],
  );
  const userNav = useMemo(() => {
    if (user && permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [user, permission]);
  const onHover = useMemo(
    () =>
      debounce((value: boolean) => {
        setHover(value);
      }, 300),
    [setHover],
  );

  useEffect(() => {
    if (user && user.appUserID) {
      // @ts-ignore
      const store_ = JSON.parse(localStorage.getItem(`menu_user-${user.appUserID}`) || '{}');
      setOpen(store_ && store_.open !== undefined ? store_.open : true);
    }
  }, [setOpen, user]);
  return (
    <>
      <div className={clsx(style.aside, 'root-left-sidebar')}>
        <div className={style.menuControl}>
          <IconButton
            onClick={() => onToggle(!open)}
            color={'inherit'}
            style={{ fontSize: '2.4rem' }}
          >
            {open ? <MenuOpenIcon fontSize={'inherit'} /> : <MenuIcon fontSize={'inherit'} />}
          </IconButton>
        </div>
        <UserNav
          menu={userNav}
          visibleTitle={false}
          fieldImagePath={fieldImagePath}
          endIcon={false}
        />
        <LayoutIconsNav />
      </div>
      <Drawer
        className={`root-drawer drawer ${open ? 'opened' : ''} ${hover ? 'hover' : ''}`}
        variant={'permanent'}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        <div className={style.logo}>
          <Logo className={style.logoLarge} />
          <LogoCircle className={style.logoSmall} />
        </div>
        <div className={style.user}>
          <UserNav
            menu={userNav}
            visibleAvatar={false}
            fieldImagePath={fieldImagePath}
            endIcon={false}
            title={(data: AppUser) => <strong className={style.userName}>{data.name}</strong>}
          />
        </div>

        <div className={style.navigation}>
          <MainNav collapsed={!open && !hover} accordion={true} />
        </div>
        <div className={style.copyright}>{t('login-copyright')}</div>
      </Drawer>
    </>
  );
};
