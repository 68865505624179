// stores
import auth from 'AurionCR/store/modules/auth';
import notify from 'AurionCR/store/modules/notify';
import routes from 'AurionCR/store/modules/routes';
import sources from 'AurionCR/store/modules/sources';
import language from 'AurionCR/store/modules/language';
import sourceSaga from 'AurionCR/store/sagas/source';

import { authAppSaga } from './auth';

export default {
  store: { auth, notify, routes, sources, language },
  sagas: [...authAppSaga, ...sourceSaga],
};
