import VAR from './_variabels.scss';
import { createTheme } from '@material-ui/core';

const FontSize = '1.4rem';

const HStyle = {
  color: VAR.colorPrimary,
  fontWeight: 600,
  textTransform: 'uppercase' as const,
};

export default createTheme({
  typography: {
    fontSize: 12,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    h1: { ...HStyle },
    h2: { ...HStyle },
    h3: { ...HStyle },
    h4: {
      ...HStyle,
      fontSize: '2.4rem',
    },
    h5: {
      ...HStyle,
      fontSize: '2rem',
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: '1.4rem',
    },
  },
  breakpoints: {
    values: {
      xs: Number(VAR.xs),
      sm: Number(VAR.sm),
      md: Number(VAR.md),
      lg: Number(VAR.lg),
      xl: Number(VAR.xl),
    },
  },
  palette: {
    text: {
      primary: '#000',
    },
    primary: {
      main: VAR.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: VAR.colorPrimaryLight,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTypography: {
      colorSecondary: {
        color: VAR.colorSecondary,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderColor: VAR.colorBorder,
        borderWidth: 2,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        color: 'rgba(0, 0, 0, 1)',
        minWidth: 64,
        fontSize: '1.2rem',
        fontWeight: 500,
        lineHeight: '1.4',
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: '1.8rem',
        },
      },
      textSecondary: {
        color: VAR.colorPrimary,
      },
      textPrimary: {
        color: VAR.colorPrimary,
      },
      sizeSmall: {
        padding: '0.4rem 1rem',
      },
      textSizeSmall: {
        fontSize: '1.1rem',
      },
      textSizeLarge: {
        fontSize: '1.4rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: '1.2rem',
      },
    },
    // GRID
    MuiGrid: {
      root: {
        '&.MuiGrid-spacing-xs-4': {
          width: 'auto',
          margin: '-10px',
        },
        '&.MuiGrid-spacing-xs-4 > .MuiGrid-item': {
          padding: '10px',
        },
      },
      item: {
        width: '100%',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
      container: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
    // DIALOG
    MuiDialogTitle: {
      root: {
        textTransform: 'none',
        padding: '1rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '1rem',
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'inherit',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '1rem',
      },
    },
    MuiFormControl: {
      root: {
        margin: '0 0 1.5rem',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, .4rem)',
        fontSize: '1.2rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: FontSize,
        color: '#888D8F',
        '&$focused': {
          color: '#888D8F',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        color: VAR.colorBorder,
      },
    },
    MuiInput: {
      root: {
        fontSize: FontSize,
      },
      underline: {
        '&:before': {
          borderBottomColor: VAR.colorBorder,
        },
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: FontSize,
      },
    },
    // @ts-ignore
    MuiPickerDTTabs: {
      tabs: {
        color: '#fff',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: VAR.colorSecondary,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: VAR.colorSecondary,
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        '& > .MuiButton-textPrimary': {
          color: VAR.colorSecondary,
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});
