const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};
export const createFilterEquals = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(names.length, names.map((_n) => `${_n}=="${prepareValue(value)}"`).join('||'))
    : undefined;
};
export const createFilterContains = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(
        names.length,
        names.map((_n) => `${_n}.contains("${prepareValue(value)}")`).join('||'),
      )
    : undefined;
};

export const createFilterWithout = <T extends Record<string, any>>(
  name: keyof T | (keyof T)[],
  value: boolean,
) => {
  const fields = Array.isArray(name) ? name : [name];
  return value ? fields.map((field) => `(${field} == "" || ${field} == null)`).join(' && ') : null;
};
