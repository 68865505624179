import { api } from 'AurionCR/components';
import { createFilterEquals } from 'utils';

export * from './behaviour';

class ServiceWithRank {
  async post<T extends { [x: string]: any } = { rank: number }>(p: {
    dataPost: T;
    rankField?: keyof T;
    apiGet: string;
    apiPost: string;
  }) {
    const { dataPost, rankField = 'rank', apiGet, apiPost } = p;
    const params = {
      take: 1,
      orderBy: `${rankField} desc`,
    };
    const result = await api.get<{ value: T[] }>(apiGet, { params });
    const item = result.data.value[0];
    const rank = Number((item ? item[rankField] : 0) || 0) + 1;
    return api.post<T>(apiPost, { ...dataPost, [rankField]: rank });
  }
}

class ServiceBase {
  async getDynamicByID<T extends Record<string, any> = { id: string }>(p: {
    url: string;
    id: string;
    params?: any;
    filterKey?: keyof T;
  }) {
    const { url, id, params, filterKey = 'id' } = p;
    const _params = {
      ...params,
      filter: [createFilterEquals<T>(filterKey, id), params?.filter].filter((v) => v).join('&&'),
      take: 1,
    };
    const result = await api.get<{ value: T[] }>(url, { params: _params });

    if (result.data.value.length === 0) {
      throw new Error('record-not-found');
    }

    return { ...result, data: result.data.value[0] };
  }
}

export const ServicePostWithRank = new ServiceWithRank();
export const ServiceAppBase = new ServiceBase();
