import React, {memo, useMemo} from "react";
import {useSource} from "AurionCR/components/source";
import style from './index.module.scss'
import {ExternalChannel} from "services/external-channels";

export const LayoutIconsNav = memo(() => {
  const {data: _data} = useSource<ExternalChannel>('ExternalChannels/GetAllDynamic?Select=id,title,description,linkURL,icon&Filter=isActive==true');
  const data = useMemo(() => {
    return _data
      .filter((item) => item.icon)
      .map((item) => ({
        ...item,
        title_: [item.title, item.description].filter((v) => !!v).join(':')
      }));
  }, [_data]);
  return data.length > 1
    ? <div className={style.nav}>
      <ul>
        {
          data.map((item) =>
            <li key={item.id}>
              <a
                href={item.linkURL || ''}
                title={item.title_}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon || ''} alt=""/>
              </a>
            </li>
          )
        }
      </ul>
    </div>
    : null
})
