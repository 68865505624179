import _ from 'lodash';

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export type PatchModel<T extends {}, ID extends keyof T> = Pick<T, ID> & Partial<Omit<T, ID>>;
export type ArrayModel<T> = T extends (infer U)[] ? U : never;

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  source: DeepPartial<Model>,
) => {
  const result = _.mergeWith(
    { ...model },
    source,
    (valueModel, valueSource, key, objMod, objSource) => {
      if (Array.isArray(valueModel) && Array.isArray(source)) {
        return source;
      }
    },
  );
  return _.pick(result, Object.keys(model)) as Model;
};

export const createObjectMap = <M extends any[]>(
  source: M,
  key: keyof ArrayModel<M>,
): { [x: string]: undefined | ArrayModel<M> } => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

export interface DeepPathResult<T> {
  isSafe: boolean;
  path: any[];

  <TKey extends keyof T>(subKey: TKey): DeepPathResult<T[TKey]>;
}

export type PromiseType<T> = T extends Promise<infer U> ? U : never;

/**
 * Create a deep path builder for a given type
 */
export function deepPath<T>() {
  /**Returns a function that gets the next path builder */
  function subPath<T, TKey extends keyof T>(parent: string[], key: TKey): DeepPathResult<T[TKey]> {
    const newPath = [...parent, key] as string[];
    const x = (<TSubKey extends keyof T[TKey]>(subKey: TSubKey) =>
      subPath<T[TKey], TSubKey>(newPath, subKey)) as DeepPathResult<T[TKey]>;
    x.path = newPath;
    return x;
  }

  return <TKey extends keyof T>(key: TKey) => subPath<T, TKey>([], key);
}

export const pick = <T, K extends keyof T>(data: T, ...args: K[]): Pick<T, K> => {
  return args.reduce((acc, key) => {
    acc[key] = data[key];
    return acc;
  }, {} as Pick<T, K>);
};
export const omit = <T, K extends keyof T>(data: T, ...args: K[]): Omit<T, K> => {
  const allKeys = Object.keys(data) as K[];
  const setExistingKeys = new Set(args);
  return allKeys.reduce((acc, key) => {
    if (!setExistingKeys.has(key)) {
      // @ts-ignore
      acc[key] = data[key];
    }
    return acc;
  }, {} as Omit<T, K>);
};

export const enumToArray = <T extends Record<string, any>>(en: T) => {
  const arrayObjects: { id: T[keyof T]; title: string }[] = [];
  // Retrieve key and values using Object.entries() method.
  for (const [propertyKey, propertyValue] of Object.entries(en)) {
    // Ignore keys that are not numbers
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }

    // Add keys and values to array
    arrayObjects.push({ id: propertyValue, title: propertyKey });
  }
  return arrayObjects;
};
