import {IUseForm} from "../interfaces/userFormHook";
import {axiosDelete, axiosGet, axiosPut} from "../requests";
import {merge} from "lodash";
import {axiosPatch} from "../requests/axiosPatch";
import {parseMixins} from "./mixins";
import {replaceNull} from "../../heplers";
import {axiosPost} from "../requests/axiosPost";

const defaults = {
  editID: true,
  fields: {},
  title: 'title',
  titleNew: 'new',
  endpoint: '',
  formHandle: null,
  get: {
    url: 'GetAllDynamic',
    field: 'id',
    request: axiosGet,
    parser: (data: any) => {
      if (data.value[0]) {
        return replaceNull({...data.value[0]})
      } else {
        return null;
      }
    }
  },
  delete: {
    // eslint-disable-next-line
    url: 'Delete/${data.id}',
    field: 'id',
    request: axiosDelete
  },
  patch: {
    // eslint-disable-next-line
    url: 'Patch/${data.id}',
    field: 'id',
    request: axiosPatch,
    parser: parseMixins
  },
  put: {
    // eslint-disable-next-line
    url: 'Update/${data.id}',
    field: 'id',
    request: axiosPut,
    parser: parseMixins
  },
  post: {
    url: 'Create',
    field: 'id',
    request: axiosPost,
    parser: parseMixins
  }
};

export const parseProps = <Model>(props: IUseForm<Model>): IUseForm<Model> => {
  const options: IUseForm<Model> = merge({}, defaults, props);
  type K = keyof Pick<IUseForm<Model>, 'get' | 'delete' | 'patch' | 'put' | 'post'>
  const keysRequest = ['get', 'delete', 'patch', 'put', 'post'] as K[]
  keysRequest.forEach((key) => {
    const requestOption = options[key]
    if (requestOption && options.endpoint && requestOption.url && typeof requestOption.url === 'string') {
      requestOption.url = `${options.endpoint}/${requestOption.url}`;
    }
  });

  return options;
};
