import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useCurrentUser } from 'hooks';
import { LayoutDesktopNav } from './desktop-nav';
import { LayoutMobileNav } from './mobile-nav';

import style from './index.module.scss';

const FIELD_IMAGE_PATH = 'userPhoto';

const Layout: FC = (props) => {
  const user = useCurrentUser();

  const isMobile = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });

  return (
    <div className={style.wrap}>
      {user && isMobile ? (
        <LayoutMobileNav user={user} fieldImagePath={FIELD_IMAGE_PATH} />
      ) : (
        <LayoutDesktopNav user={user} fieldImagePath={FIELD_IMAGE_PATH} />
      )}
      <main className={style.content}>{props.children}</main>
    </div>
  );
};

export default Layout;
