import { useSource } from 'AurionCR/components';
import { API_LANGUAGES, DIRECTIONS, Language } from 'services/labels';
import { useMemo } from 'react';
import { useMap } from './use-map';
import { useTranslate } from './use-translate';
import { API_HEALTH_ALERT_LEVELS } from 'services/health-alert-lavels';
import { JEWELRY_CATEGORY_TYPE } from 'services/jewelry-categories';
import { enumToArray } from 'utils';
import { fieldToLabelKey } from 'utils/other';

type DefaultSource = { id: string; title: string };

export const useSourceTranslate = <
  M extends Record<string, any> = { id: string },
  T extends { data: M[] } = { data: [] },
>(
  props: T,
  key: keyof M = 'id',
) => {
  const { t } = useTranslate();
  return useMemo(() => {
    return { ...props, data: props.data.map((item) => ({ ...item, [key]: t(item[key]) })) };
  }, [props, key, t]);
};
export const useSourcesLanguages = () => {
  return useSource<Language>(
    `${API_LANGUAGES.GET_ALL_DYNAMIC}?select=id,title,direction`,
    'id',
    true,
  );
};
export const useSourcesHealthAlertLevels = () => {
  return useSource<DefaultSource>(
    `${API_HEALTH_ALERT_LEVELS.GET_ALL_DYNAMIC}?select=id,labelKey as title&filter=isActive==true&orderBy=labelKey asc`,
    'id',
    true,
  );
};

export const useSourceDirection = () => {
  const { t } = useTranslate();
  const data = useMemo<{ id: DIRECTIONS; title: string }[]>(() => {
    return [DIRECTIONS.LTR, DIRECTIONS.RTL].map((key) => {
      switch (key) {
        case DIRECTIONS.LTR:
          return { id: key, title: t('ltr') };
        default:
          return { id: key, title: t('rtl') };
      }
    });
  }, [t]);
  const map = useMap(data, 'id');
  return { data, map };
};

export const useSourceJeweleryTypes = () => {
  const { t } = useTranslate();
  const data = useMemo<{ id: JEWELRY_CATEGORY_TYPE; title: string }[]>(() => {
    return enumToArray(JEWELRY_CATEGORY_TYPE).map((item) => {
      return { ...item, title: t(fieldToLabelKey(item.title)) };
    });
  }, [t]);
  const map = useMap(data, 'id');
  return { data, map };
};
