import { API_MEDIA_UPLOADS, MediaUploadFile, MediaUploadHtml } from './models';
import { api, getRandomString } from 'AurionCR/components';
import { validateURL } from 'AurionCR/components/formV2';
import { setToModel } from 'utils';

export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};

export const MEDIA_PREFIX = '@@CLOUD@@';

export * from './models';

interface Model {
  name: string;
  value: string;
}

class Service {
  async tryRemove(filePath: string) {
    try {
      await ServiceMediaUploads.remove(filePath);
    } catch (e) {
      console.warn(e);
    }
  }

  async remove(filePath: string) {
    return api.delete(API_MEDIA_UPLOADS.REMOVE_FILE({ filePath: filePath }));
  }

  async uploadFile(data: Model) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_FILE, data);
  }

  async getHtml(url?: string) {
    if (url && validateURL(url) === undefined) {
      const response = await fetch(url);
      const text = await response.text();
      return { data: text };
    }
    return { data: '' };
  }

  async uploadHtml(data: { htmlContent: string; fileName: string }) {
    return api.post<MediaUploadHtml>(API_MEDIA_UPLOADS.UPLOAD_HTML, { ...data });
  }

  async uploadImage(data: Model) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_IMAGE, data);
  }

  private async upload(url: string, data: Model) {
    const fileName = `${MEDIA_PREFIX}__${data.name ? data.name : getRandomString(20)}`;
    const fileStreamString = base64ToFileStream(data.value);
    return api.post<MediaUploadFile>(
      url,
      setToModel(new MediaUploadFile(), { fileName, fileStreamString }),
    );
  }
}

export const ServiceMediaUploads = new Service();
