import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3441 8.17248L16.1716 5H7.82843L4.65594 8.17248L12 17.7198L19.3441 8.17248ZM22 8L17 3H7L2 8L12 21L22 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8807 8.08695L12.6459 5H11.3541L10.1193 8.08695L12 14.1993L13.8807 8.08695ZM16 8L14 3H10L8 8L12 21L16 8Z"
    />
  </svg>,
  'DiamondIcon',
);
