import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'AurionCR/components/hooks';

interface Options<T> {
  name: string;
  init?: T;
}
export const useStateQuery = <T extends string | number | boolean | null>(options: Options<T>) => {
  const { name, init } = options;

  let { query, replace } = useSearchParams();

  const onChange = useCallback(
    (value: T) => {
      replace({ [name]: value });
    },
    [name, replace],
  );
  const value = useMemo(() => {
    return query[name];
  }, [name, query]);

  const refOnce = useRef(false);
  useEffect(() => {
    if (init && !value && !refOnce.current) {
      refOnce.current = true;
      onChange(init);
    }
  }, [value, init, onChange]);
  return [value, onChange] as const;
};
