const _API_CRM = 'UserCrmProfiles';

export const API_USERS_CRM = {
  API: _API_CRM,
  GET_ALL_DYNAMIC: `${_API_CRM}/GetAllDynamic`,
  POST: `${_API_CRM}/Create`,
  PATCH: ({ appIdentityUserID }: Pick<UserCrmProfile, 'appIdentityUserID'>) =>
    `${_API_CRM}/Patch/${appIdentityUserID}`,
  DELETE: ({ appIdentityUserID }: Pick<UserCrmProfile, 'appIdentityUserID'>) =>
    `${_API_CRM}/Delete/${appIdentityUserID}`,
};

const _API_PERMISSIONS = 'UserCrmProfilePermissions';
export const API_PERMISSIONS = {
  API: _API_PERMISSIONS,
  GET_ALL_DYNAMIC: `${_API_PERMISSIONS}/GetAllDynamic`,
  POST: `${_API_PERMISSIONS}/Create`,
  PATCH: ({ id }: { id: string }) => `${_API_PERMISSIONS}/Patch/${id}`,
  DELETE: ({ id }: { id: string }) => `${_API_PERMISSIONS}/Delete/${id}`,
};

export class UserCrmProfilePatch {
  isActive = true;
  userCrmProfilePermissionID = '';
  languageID = '';
  firstName = '';
  lastName = '';
  userPhoto = '';
  mobilePhone = '';
}

export class UserCrmProfile extends UserCrmProfilePatch {
  email: string = '';
  generatePasswordEndDate: string = '';
  id!: string;
  appIdentityUserID!: string;
  readonly fullName?: string;
}

export interface UserCrmProfilePermission {
  id: string;
  rowIndex: number;
  isActive: boolean;
  title: string;
  isAllowToEditSetting: boolean;
  isAllowToEditLabels: boolean;
  isAllowToEditProfile: boolean;
  isAllowToEditCrmUsers: boolean;
  isAllowToEditPermissions: boolean;
  isAllowToViewLog: boolean;
  isAllowToViewSensitiveInformation: boolean;
  isAllowToResetSensitiveInformation: boolean;
  userCrmProfiles: UserCrmProfile[];
}

export enum PERMISSIONS {
  EDIT_SETTING = 'isAllowToEditSetting',
  EDIT_LABELS = 'isAllowToEditLabels',
  EDIT_PROFILE = 'isAllowToEditProfile',
  EDIT_CRM_USERS = 'isAllowToEditCrmUsers',
  EDIT_PERMISSIONS = 'isAllowToEditPermissions',
  VIEW_LOG = 'isAllowToViewLog',
  VIEW_SENSITIVE_INFORMATION = 'isAllowToViewSensitiveInformation',
  RESET_SENSITIVE_INFORMATION = 'isAllowToResetSensitiveInformation',
}
