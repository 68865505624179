import React, { FC } from 'react';
// style
import theme from 'styles/theme';
import 'AurionCR/styles/_main.scss';
import 'styles/index.scss';
// store
import { Provider } from 'react-redux';
import store from 'AurionCR/store';
import storeConfig from 'store/redux';
// layout
import Page404 from 'AurionCR/pages/Page404';
import Layout from 'components/Layout';
// router
import routesConfig from 'configs/routes';
// notify
import { SnackbarProvider } from 'notistack';
// l18n
import {
  AuthContextProvider,
  I18nContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
} from 'AurionCR/components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AxiosInterceptors } from 'components/axios-interceptors';
import RTLThemeProvider from 'components/rtl-theme-provider';

declare global {
  interface Window {
    authCookieName_: string;
    authRefreshCookieName_: string;
    authDateCookieName_: string;
  }
}
const App: FC = () => {
  window.authCookieName_ = 'direct_ice_crm_auth_token';
  window.authRefreshCookieName_ = 'direct_ice_crm_auth_refresh_token';
  window.authDateCookieName_ = 'direct_ice_crm_auth_expires_token';

  return (
    <Provider store={store(storeConfig)}>
      <AxiosInterceptors>
        <AuthContextProvider>
          <DndProvider backend={HTML5Backend}>
            <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
              <RTLThemeProvider theme={theme}>
                <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Notifier />
                  <LoadingOverlayProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Routes layout={Layout} routes={routesConfig} page404={Page404} />
                    </MuiPickersUtilsProvider>
                  </LoadingOverlayProvider>
                </SnackbarProvider>
              </RTLThemeProvider>
            </I18nContextProvider>
          </DndProvider>
        </AuthContextProvider>
      </AxiosInterceptors>
    </Provider>
  );
};

export default App;
